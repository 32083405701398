<template>
  <div>
    <NavBar
      style="max-height: 50px;margin-bottom: 10px;"
      :username="currentUserName"
      class="topNavBar"
    ></NavBar>
    <v-app>
      <div style="background-color: #f5faff; height: 93.5vh !important;">
       <v-row cols="12">
        <v-col cols="2">
        <SideBar style="max-height: 50px;" :pageName="pageName" class="sideBar"></SideBar>
        </v-col>
          <v-col cols="10" style="overflow: scroll; height: 90vh;">
            <div
              class="header"
              style="
                display: flex;
                flex-direction: row;
                padding-top: 10px;
                padding-left: 5px;
              ">
              <div class="bookAppointmentMainDiv" style="display: flex; flex-direction: row; width: 100%">
                <div
                    style="width: 10%; padding-top: 6px"
                    align="left"
                    v-on:click="redirectToHomePage()"
                  >
                  <img src="https://img.icons8.com/material-outlined/20/000000/left.png"/>
                </div>
                <div style="width: 90%" align="left">
                  <span style="font-weight: 600; font-size: 15pt">Prescription</span>
                </div>
              </div>
            </div>
            <v-card style="padding: 32px 24px">
              <v-row>
                <v-col cols="12">
                  <div
                    style="display: flex; flex-direction: row; margin-top: 10px"
                  >
                    <div>Booking ID:</div>
                    <div align="left" style="width: 50%">
                      <span style="padding-left: 1%; font-weight: 600">{{
                        appointment_reference_number
                      }}</span>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="12">
                  <div id="form" style="width: 95%">
                    <fieldset class="fieldset_class" style="padding:15px">
                      <legend align="left" style="font-weight: bold">
                        Covid Related Symptoms
                      </legend>
                      <v-row>
                        <v-col cols="12" sm="4" md="4">
                          <v-checkbox
                            disabled
                            v-model="selected"
                            label="Fever"
                            value="Fever"
                          ></v-checkbox>
                          <v-checkbox
                            disabled
                            v-model="selected"
                            label="Shortness of breath"
                            value="Shortness of breath"
                          ></v-checkbox>
                          <v-checkbox
                            disabled
                            v-model="selected"
                            label="Flu like illness(Sneezing, cough)"
                            value="Flu like illness(Sneezing, cough)"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <v-checkbox
                            disabled
                            v-model="selected"
                            label="Close contact with a confirmed or probable case of COVID-19 suspected patient"
                            value="Close contact with a confirmed or probable case of COVID-19 suspected patient"
                          ></v-checkbox>
                          <v-checkbox
                            disabled
                            v-model="selected"
                            label="Contact with a person who had travelled to COVID-19 suspected areas"
                            value="Contact with a person who had travelled to COVID-19 suspected areas"
                          ></v-checkbox>
                          <v-checkbox
                            v-model="selected"
                            label="None of the Above"
                            value="None of the Above"
                            disabled
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <v-checkbox
                            v-model="selected"
                            label="Loss of smell"
                            value="No Smell"
                            disabled
                          ></v-checkbox>
                          <v-checkbox
                            disabled
                            v-model="selected"
                            label="Travelling history within 2 weeks"
                            value="Travelling history within 2 weeks"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </fieldset>
                  </div>
                </v-col>
              </v-row>
              <v-divider />
              <v-row style="width: 95%; margin-top: 10px; margin-left: 10px; padding:15px;">
                <v-col cols="10" sm="5" md="4" align="left">
                  Allergies
                  <v-textarea
                    class="textarea_class"
                    v-model="allergies"
                    solo
                    rows="1"
                    disabled
                  ></v-textarea>
                </v-col>
                <v-col cols="10" sm="5" md="4" align="left">
                  Diagnosed With
                  <v-textarea
                    class="textarea_class"
                    v-model="diagnosed_with"
                    solo
                    rows="1"
                    disabled
                  ></v-textarea>
                </v-col>
                <v-col cols="10" sm="5" md="4" align="left">
                  Current Taking Medicines
                  <v-textarea
                    class="textarea_class"
                    v-model="current_taking_medicines"
                    solo
                    rows="1"
                    disabled
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-divider />
              <v-row style="width: 95%; margin-top: 10px; margin-left: 10px;">
                <v-col cols="6" sm="6" md="6" align="left">
                  Prescription
                </v-col>
                <v-col cols="12" sm="12" md="12" align="left">
                  <table style="width: 100%">
                    <tr align="left" style="padding: 15px">
                      <td style="font-size: 16px; line-height: 20px">
                        Medicine
                      </td>
                      <td style="font-size: 16px; line-height: 20px">
                        Unit(Tablet/Syrup)
                      </td>
                      <td style="font-size: 16px; line-height: 20px">Dosage</td>
                    </tr>
                    <tr
                      style="padding: 15px; font-weight: 300;   margin-left: 10px;"
                      v-for="(item, index) in prescription_object"
                      :key="index"
                    >
                      <td>
                        <v-text-field
                          class="textfield_class"
                          solo
                          v-model="item.medicine"
                          disabled
                        />
                      </td>
                      <td>
                        <v-text-field
                          class="textfield_class"
                          solo
                          v-model="item.unit"
                          disabled
                        />
                      </td>
                      <td>
                        <v-text-field
                          class="textfield_class"
                          solo
                          v-model="item.dosage"
                          disabled
                        />
                      </td>
                    </tr>
                  </table>
                </v-col>
              </v-row>
              <v-divider />
              <v-row style="width: 95%; margin-top: 10px; margin-left: 10px; padding: 15px;">
                <v-col cols="10" sm="5" md="6" align="left">
                  Suggestions (If Any)
                  <v-textarea
                    class="textarea_class"
                    v-model="suggestions"
                    solo
                    disabled
                    rows="1"
                  ></v-textarea>
                </v-col>
                <v-col cols="10" sm="5" md="6" align="left">
                  Examinations to be done (If Any)
                  <v-textarea
                    class="textarea_class"
                    v-model="exams"
                    solo
                    rows="1"
                    disabled
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-divider />
              <v-row style="width: 95%; margin-top: 10px ; margin-left: 10px">
                <v-col cols="3" sm="3" md="3">
                  <v-checkbox
                    disabled
                    v-model="need_follow_up"
                    label="Need Follow up appointment"
                    value="true"
                  ></v-checkbox>
                </v-col>
                <v-col cols="3" sm="3" md="3">
                  <v-checkbox
                    disabled
                    v-model="referred_to_specialist"
                    label="Referred to a specialist"
                    value="true"
                  ></v-checkbox>
                </v-col>
                <v-col cols="3" sm="3" md="3">
                  <v-checkbox
                    disabled
                    v-model="patient_no_show"
                    label="Patient No Show"
                    value="true"
                  ></v-checkbox>
                </v-col>
                <v-col cols="3" sm="3" md="3">
                  <v-checkbox
                    disabled
                    v-model="unstable_connectivity"
                    label="Unstable Connectivity"
                    value="true"
                  ></v-checkbox>
                </v-col>
              </v-row> -->
              <v-row style = "margin: 10px 0px;">
                <v-col cols="2" lg="2" sm="2" md="2" xl="2" align="left" class="persistingSymptomsClass presentingSysmptoms">
                  Presenting Symptoms
                </v-col>
                <v-col cols="10" lg="10" sm="10" md="10" xl="10" class="persistingSymptomsClass presentingSysmptoms">
                  <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="presenting_symptoms" solo rows=1></v-textarea> -->
                  <p class="variable">{{presenting_symptoms}}</p>
                </v-col>
              </v-row>
              <v-divider/>
              <v-row  style = "margin: 10px 0px;">
                <v-col style="border-style: hidden groove hidden hidden;" cols="4" lg="4" sm="4" md="4" xl="4" class="docViewAppointmentMedicalDetails">
                  Past medical history
                  <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="medical_history" solo rows=1></v-textarea> -->
                  <p class="variable">{{medical_history}}</p>
                </v-col>
                <v-col style="border-style: hidden groove hidden hidden" cols="4" lg="4" sm="4" md="4" xl="4" class="docViewAppointmentMedicalDetails">
                  Past surgical history
                  <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="surgical_history" solo rows=1></v-textarea> -->
                  <p class="variable">{{surgical_history}}</p>
                </v-col>
                <v-col cols="4" lg="4" sm="4" md="4" xl="4" class="docViewAppointmentMedicalDetails">
                  Family history
                  <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="family_history" solo rows=1></v-textarea> -->
                  <p class="variable">{{family_history}}</p>
                </v-col>
              </v-row>
              <v-divider/>
              <v-row  style = "margin: 10px 0px;">
                <v-col style="border-style: hidden groove hidden hidden;" cols="4" lg="4" sm="4" md="4" xl="4" class="docViewAppointmentMedicalDetails">
                  Regular taking/ Current medicine
                  <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="current_taking_medicines" solo rows=1></v-textarea> -->
                  <p class="variable">{{current_taking_medicines}}</p>
                </v-col>
                <v-col style="border-style: hidden groove hidden hidden;" cols="4" lg="4" sm="4" md="4" xl="4" class="docViewAppointmentMedicalDetails">
                  Known Drug Allergies
                  <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="allergies" solo rows=1></v-textarea> -->
                  <p class="variable">{{allergies}}</p>
                </v-col>
                <v-col cols="4" lg="4" sm="4" md="4" xl="4" class="docViewAppointmentMedicalDetails">
                  Vaccination History
                  <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="vaccination_history" solo rows=1></v-textarea> -->
                  <p class="variable">{{vaccination_history}}</p>
                </v-col>
              </v-row>
              <v-divider/>
              <v-row  style = "margin: 10px 0px;">
                <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left" class="docViewAppointmentMedicalDetails">
                  Diagnosis
                </v-col>
                <v-col cols="10" lg="10" sm="10" md="10" xl="10" class="docViewAppointmentMedicalDetails">
                  <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="diagnosed_with" solo rows=1></v-textarea> -->
                  <p class="variable">{{diagnosed_with}}</p>
                </v-col>
              </v-row>
              <v-divider/>
              <v-row  style = "margin: 10px 0px;">
                <v-col cols="3" sm="3" md="3" align="left" class="docViewAppointmentMedicalDetails">
                  Treatment
                </v-col>
                <v-col cols="12" sm="12" md="12" align="left">
                  <table style=" width: 100%; background-color: #F1F6FF;" class="prescriptionTableClass">
                    <tr align="left" style="padding: 15px;">
                      <th style="font-size:16px; line-height: 20px; padding: 5px;">Medicine</th>
                      <th style="font-size:16px; line-height: 20px; padding: 5px;">Dosage</th>
                      <th style="font-size:16px; line-height: 20px; padding: 5px;">Frequency</th>
                      <th style="font-size:16px; line-height: 20px; padding: 5px;">Duration</th>
                    </tr>
                    <tr style = "padding: 15px; font-weight: 300;" v-for="(item,index) in prescription_object" :key=index>
                       <td style="padding:5px;font-weight: 500">{{item.medicine}}</td>
                       <td style="padding:5px;font-weight: 500">{{item.dosage}}</td>
                       <td style="padding:5px;font-weight: 500">{{item.frequency}}</td>
                       <td style="padding:5px;font-weight: 500">{{item.duration}}</td>
                    </tr>
                  </table>
                  <table style=" width: 100%; background-color: #F1F6FF; margin-top:18px" class="PrescriptionTableMobileLayout" v-for="(item,index) in prescription_object" :key=index>
                      <tr align="left">
                        <td style="font-size:12px; line-height: 20px; padding: 5px;">Medicine</td>
                      </tr>
                      <tr>
                        <td style="padding:5px;"><p>{{item.medicine}}</p></td>
                      </tr>
                      <tr>
                        <table width="100%">
                          <tr>
                            <td style="font-size:12px; line-height: 20px; padding: 5px; width:33%">Dosage</td>
                            <td style="font-size:12px; line-height: 20px; padding: 5px;width:33%">Frequency</td>
                            <td style="font-size:12px; line-height: 20px; padding: 5px;width:33%">Duration</td>
                          </tr>
                          <tr >
                            <td style="padding:5px;"><p>
                            {{item.dosage}}</p>
                            </td>
                            <td style="padding:5px;"><p>{{item.frequency}}</p></td>
                            <td style="padding:5px;"><p>{{item.duration}}</p></td>
                          </tr>
                        </table>
                      </tr>
                    </table>
                </v-col>
              </v-row>
              <v-divider/>
               <v-row  style = "margin: 10px 0px;">
                <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left">
                  Suggestions
                </v-col>
                <v-col cols="10" lg="10" sm="10" md="10" xl="10">
                  <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="investigations_needed" solo rows=1></v-textarea> -->
                  <p class="variable">{{investigations_needed}}</p>
                </v-col>
              </v-row>
              <v-row  style = "margin: 10px 0px;">
                <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left">
                  Investigations needed
                </v-col>
                <v-col cols="10" lg="10" sm="10" md="10" xl="10">
                  <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="investigations_needed" solo rows=1></v-textarea> -->
                  <p class="variable">{{investigations_needed}}</p>
                </v-col>
              </v-row>
              <v-divider/>
              <v-row  style = "margin: 10px 0px;">
                <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left">
                  To Come after
                </v-col>
                <v-col cols="3" lg="3" sm="3" md="3" xl="3">
                  <!-- <v-select disabled background-color="#F1F6FF" solo v-model="come_after" :items="follow_up_list"/> -->
                  <p class="variable">{{come_after}}</p>
                </v-col>
              </v-row>
              <v-divider/>
              <v-row  style = "margin: 10px 0px;">
                <v-col cols="2" lg="2" sm="2" md="2" xl="2"  align="left">
                  Refer To
                </v-col>
                <v-col cols="3" lg="3" sm="3" md="3" xl="3">
                  <!-- <v-select disabled background-color="#F1F6FF" solo v-model="selected_specialization" :items="specialization_list" item-text="specialization_name" item-value="_id" return-object/> -->
                  <p class="variable">{{selected_specialization.specialization_name}}</p>
                </v-col>
                <v-col cols="3" lg="3" sm="3" md="3" xl="3" v-if="selected_specialization.specialization_name == 'Others'">
                  <!-- <v-textarea disabled background-color="#F1F6FF" class="textarea_class" v-model="others" solo rows=1></v-textarea> -->
                  <p class="variable">{{others}}</p>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-app>
  </div>
</template>


<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
import SideBar from "../../../views/sideBar.vue";
import { axios_auth_instance_doctor } from '../../../utils/axios_utils';
import { handleError } from '../../../utils/utils';


export default {
  name: "viewPrescription",
  data() {
    return {
      pageName: "DoctorViewPrescription",
      prescriptionID: "",
      currentUser: "",
      currentUserName: "",
      appointment_reference_number: "",
      age: "",
      prescriptionId: "",
      particularPrescriptionDetails: "",
      unstable_connectivity: false,
      patient_no_show: false,
      referred_to_specialist: false,
      need_follow_up: false,
      exams: "",
      suggestions: "",
      prescription_object: [{ medicine: "", dosage: "", frequency: "", duration: "" }],
      current_taking_medicines: "",
      diagnosed_with: "",
      allergies: "",
      selected: [],
      break_here_for_newtemplate_variable: "",
      presenting_symptoms: "",
      medical_history: "",
      surgical_history: "",
      family_history: "",
      current_taking_medicines: "",
      allergies: "",
      vaccination_history: "",
      diagnosed_with: "",
      investigations_needed: "",
      prescription_object: [{ medicine: "", dosage: "", frequency: "", duration: "" }],
      follow_up_list: ["1 Day", "2 Days", "4 Days", "7 Days","Not Required"],
      come_after: "",
      others: "",
      specialization_list: [],
      selected_specialization: ""
    };
  },
  components: {
    NavBar,SideBar
  },
  mounted() {
    this.currentUser = this.$cookies.get("doctorToken");
    document.title = "View Prescription"
    var presc_dets = "";
    if (!this.currentUser) {
      this.$router.push({
        name: "DoctorLogin",
      });
    } else {
      var params = this.$route.params;
      this.prescriptionID = params["prescription_id"];
      var data = {
        token : this.currentUser,
        typeOfUser : "DOCTOR",
        prescriptionID : this.prescriptionID
      };
      axios_auth_instance_doctor.post("/doctorViewPrescriptionPage", data)
      .then((successResponse) => {
        this.particularPrescriptionDetails = successResponse.data.prescription_data;
        this.appointment_reference_number = this.particularPrescriptionDetails.appointment_reference_number;
        presc_dets = this.particularPrescriptionDetails;
        this.selected = presc_dets['covid_related_symptoms'];
        this.allergies = presc_dets['allergies'];
        this.diagnosed_with = presc_dets['diagnosed_with'];
        this.current_taking_medicines = presc_dets['current_taking_medicines'];
        this.suggestions = presc_dets['suggestion'];
        if(presc_dets['need_follow_up'] == true)
          this.need_follow_up = "true"
        this.exams = presc_dets['examination_to_be_done'];
        if(presc_dets['patient_no_show'] == true)
          this.patient_no_show = presc_dets['patient_no_show'];
        if(presc_dets['referred_to_specialist'] == true)
          this.referred_to_specialist = "true";
        if(presc_dets['unstable_connectivity'] == true)
        {
          this.unstable_connectivity = "true";
        }
        if(presc_dets['prescription'].length > 0) {
          this.prescription_object = presc_dets['prescription'];
          this.presenting_symptoms = presc_dets.presentSymptoms;
          this.medical_history = presc_dets.medicalHistory;
          this.surgical_history = presc_dets.surgicalHistory;
          this.family_history = presc_dets.familyHistory;
          this.vaccination_history = presc_dets.vaccinationHistory;
          this.investigations_needed = presc_dets.investigationNeeded;
          this.suggestions = presc_dets.suggestion;
          this.come_after = presc_dets.next_followup_date;
          axios.post(process.env.VUE_APP_BACKEND_URL+"/readAllSpecializations", data)
          .then((readAllSpecializationsResponse) => {
            var spec_list = readAllSpecializationsResponse.data.data;
            this.specialization_list.push({'_id': "00000", 'specialization_key': "others", 'specialization_name':"Others"});
            spec_list.forEach((x) =>{
              this.specialization_list.push({'_id': x._id, 'specialization_key': x.specialization_key, 'specialization_name': x.specialization_name});
            });
            let obj = this.specialization_list.find(o => o['specialization_name'] === presc_dets.referredSpecialistName);
            if(obj)
            {
              this.selected_specialization = obj;
            }
            else if(presc_dets)
            {
              if(presc_dets.referredSpecialistName == "Not Required")
              {
                this.selected_specialization = {'_id': "00001", 'specialization_key': "not_required", 'specialization_name':"Not Required"};
              }
              else
              {
                this.selected_specialization = {'_id': "00000", 'specialization_key': "others", 'specialization_name':"Others"};
                this.others = presc_dets.referredSpecialistName;
              }
            }
          })
          .catch((errorResponse) => {
            if (readParticularPrescriptionError.response.status == 401) {
              window.alert("Sorry !! You are UNAUTHORIZED");
              this.$router.push({
                name: "DoctorLogin",
              });
            }
            else{
              window.alert("Something went wrong...");
            }
          });
        }
    })
    .catch((errorResponse) => {
        handleError(errorResponse, this.$router, 'doctorViewPrescriptionPage', 'DoctorLogin', 'doctorToken');
    });
    }
  },
  methods:{
    redirectToHomePage(){
      this.$router.go(-1);
    }
  }
}
</script>

<style scoped>
.fieldset_class
  {
    border-right-style: none;
    border-left-style: none;
    border-top-style: none;
    border-bottom-style: none;
  }
  .textfield_class {
    box-sizing: border-box;
    border-radius: 8px;
  }
  th {
    background-color: #1467bf;
    color: white;
    border: 0px;
    font-size: 8pt;
    font-weight: 500;
    margin-left: 10%;
    position: sticky;
    top: 0;
    z-index: 10;
    height: 40px;
  }
  .variable {
    font-weight: 600;
  }
  .header{
    display: none !important;
  }
  @media (min-width: 320px) and (max-width: 1024px) {
    .topNavBar,.sideBar {
      display: none !important;
    }
    .col-2, .col-10{
      flex: unset !important;
      max-width: unset !important;
      padding: unset !important;
    }
    .header {
      display: block !important;
      box-shadow: 0px 2px 15px 0px #00000026;
      padding: 16px 20px !important;
      margin-top: 12px !important;
    }
    .persistingSymptomsClass{
      max-width: unset;
      flex: unset;
      padding:unset;
    }
    .presentingSysmptoms{
      padding-left:12px ;
    }
    .docViewAppointmentMedicalDetails{
        max-width: unset;
        flex: unset;
        text-align: left;
    }
    .prescriptionTableClass{
      display: none;
    }
  }
</style>